// imports
import Link from 'next/link';

// lib files
import { VideoTypeEnum } from '@/lib/types/api/video';

// components
import ITSImage from '@/components/ITSImage/ITSImage';
import LinkButton from '@/components/Button/LinkButton';
import MezzanineVideoInitializer from '@/components/MezzanineVideoInitializer/MezzanineVideoInitializer';

// styles
import styles from './LiveEventHero.module.scss';
interface LiveEventHeroProps {
  liveEventHeroDescription: string;
  liveEventHeroHeadline: string;
  liveEventHeroImageAlt: string;
  liveEventHeroImageUrl: string;
  liveEventHeroShowLogoAlt: string;
  liveEventHeroShowLogoUrl: string;
  liveEventHeroUrl: string;
}
const LiveEventHero = (props: LiveEventHeroProps) => {
  const {
    liveEventHeroDescription,
    liveEventHeroHeadline,
    liveEventHeroImageAlt,
    liveEventHeroImageUrl,
    liveEventHeroShowLogoAlt,
    liveEventHeroShowLogoUrl,
    liveEventHeroUrl
  } = props;
  return <section className={styles.live_event_hero} data-sentry-component="LiveEventHero" data-sentry-source-file="LiveEventHero.tsx">
      <MezzanineVideoInitializer videoType={VideoTypeEnum.Live} imgSrc={liveEventHeroImageUrl} alt={liveEventHeroImageAlt} showWatchButton={false} href={liveEventHeroUrl} prefetch={false} verticalOffsetButton={true} width={1440} className={styles.live_event_hero_background} data-sentry-element="MezzanineVideoInitializer" data-sentry-source-file="LiveEventHero.tsx" />
      <div className={styles.live_event_hero_overlay}>
        <h2 className={styles.live_event_show_logo}>
          <Link href={liveEventHeroUrl} data-sentry-element="Link" data-sentry-source-file="LiveEventHero.tsx">
            <ITSImage src={liveEventHeroShowLogoUrl} alt={liveEventHeroShowLogoAlt} width={240} data-sentry-element="ITSImage" data-sentry-source-file="LiveEventHero.tsx" />
          </Link>
        </h2>
        <h3 className={styles.live_event_title}>
          <Link href={liveEventHeroUrl} data-sentry-element="Link" data-sentry-source-file="LiveEventHero.tsx">
            {liveEventHeroHeadline}
          </Link>
        </h3>
        <p className={styles.live_event_description}>
          {liveEventHeroDescription}
        </p>

        <div className={styles.live_event_hero_overlay_buttons}>
          <LinkButton href={liveEventHeroUrl} style="white" iconBefore="play" data-sentry-element="LinkButton" data-sentry-source-file="LiveEventHero.tsx">
            Watch Live
          </LinkButton>
        </div>
      </div>
    </section>;
};
export default LiveEventHero;