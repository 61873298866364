'use client';

import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { userProfile } from '@/lib/atoms/profile';
import ShowRow from "@/components/ShowRow/ShowRow";
import { recommendedShowsAtom } from '@/lib/atoms/recommended-shows';
import { ProfileData } from '@/lib/types/api/profile-data';
import { ShowRowContent } from '@/lib/types/api/show-data';
interface TopPicksShowRowProps {
  profileStub?: ProfileData;
  depTopPicksShowData?: ShowRowContent[];
}
const TopPicksShowRow = (props: TopPicksShowRowProps) => {
  const {
    profileStub,
    depTopPicksShowData
  } = props;
  const [profile] = useAtom(userProfile);
  const userFirstName = profileStub ? profileStub.profile.first_name : profile?.profile?.first_name;
  const [topPicksData, setTopPicksData] = useAtom(recommendedShowsAtom);

  // Fetch top picks data (recommended shows) when component is loaded
  useEffect(() => {
    const fetchTopPicksData = async () => {
      try {
        const response = await fetch(`/api/profile/recommended-shows/`);
        const json = await response.json();
        const topPicksResponse = json.recommendedShowsData;
        setTopPicksData(topPicksResponse);
      } catch (error) {
        console.error(error);
      }
    };
    if (depTopPicksShowData) {
      setTopPicksData(depTopPicksShowData);
    } else {
      if (profile) {
        fetchTopPicksData();
      }
    }
  }, [profile, depTopPicksShowData, setTopPicksData]);
  return <>
      {topPicksData && <ShowRow shows={topPicksData} title={`Top Picks for ${userFirstName ? userFirstName : "You"}`} />}
    </>;
};
export default TopPicksShowRow;