import { ContinueWatchingContent } from '@/lib/types/api/home-data';
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils';
import CompanionState from '@/lib/types/atoms/companionState';

import createStorage from '@/lib/helpers/createStorage';
import { canAccessStorage } from '@/lib/helpers/is-storage-available';

const storage = createStorage<ContinueWatchingContent[] | null>('sessionStorage')

let storedContinueWatching = null

if (canAccessStorage('sessionStorage')) {
  storedContinueWatching = JSON.parse(window.sessionStorage.getItem('continueWatching')!) as ContinueWatchingContent[]
}

export const continueWatchingAtom = atomWithStorage<ContinueWatchingContent[] | null>('continueWatching', storedContinueWatching, storage);
export const continueWatchingStatusAtom = atom<CompanionState>(CompanionState.NotLoaded)
